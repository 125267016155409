
.index {
    box-sizing: border-box;
    height: 100%;
    padding: 20px 20px 0;
    .assign-content {
        position: relative;
        box-sizing: border-box;
        height: 279px;
        border: 1px solid #dddfeb;
        .paper-list {
            padding: 0 40px 0 10px;
            .empty {
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%);
                color: #999;
            }
            .paper-item {
                display: flex;
                align-items: center;
                padding: 10px 0;
                cursor: pointer;
                &:hover .label {
                    color: #2821fc;
                }
                .label {
                    box-sizing: border-box;
                    margin-left: 10px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    color: #1b162a;
                    overflow: hidden;
                    transition: all 0.3s;
                }
            }
        }
    }
}
.assign-select {
    #label {
        color: #2821fc;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
